import React, { useEffect } from "react";
import gsap from 'gsap';

const BackToTopButton = () => {

    useEffect(() => { 
        const btt = document.querySelector(".mil-back-to-top .mil-link");

        gsap.set(btt, {
            x: -30,
            opacity: 0,
        });
    
        gsap.to(btt, {
            x: 0,
            opacity: 1,
            ease: 'sine',
            scrollTrigger: {
                trigger: "body",
                start: "top -40%",
                end: "top -40%",
                toggleActions: "play none reverse none"
            }
        });
    }, []);

    return (
        <div className="mil-frame-bottom d-none d-xl-flex">
            <div className="mil-current-page">
            </div>
            <div className="mil-back-to-top">
                <a href="#top" className="mil-link mil-dark mil-arrow-place" style={{translate: 'none', rotate: 'none', scale: 'none', opacity: 1, transform: 'translate(0px, 0px)'}}>
                    <span>Zpět na začátek</span>
                </a>
            </div>
        </div>
    );
};

export default BackToTopButton;