import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Products = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 300,
    fade: true,
    cssEase: "linear",
  };

  const goToFirstSlide = () => {
    sliderRef.current.slickGoTo(0);
  };

  const goToSecondSlide = () => {
    sliderRef.current.slickGoTo(1);
  };

  const BasicControlBtn = () => {
    return (
      <div className="mil-button-carusel" onClick={goToFirstSlide}>
        <span>Before</span>
      </div>
    );
  };

  const CustomControlBtn = () => {
    return (
      <div className="mil-button-carusel" onClick={goToSecondSlide}>
        <span>After</span>
      </div>
    );
  };

  return (
    <section id="products">
      <div className="mi-invert-fix">
        <div className="mil-animation-frame"></div>
        <div className="container mil-p-120-0">
          <div className="mil-mb-60">
            <div className="mil-complex-text justify-content-center mil-up mil-mb-15">
              <h2 className="mil-h1 mil-up mil-center">
                <span className="mil-thin">NAŠE</span> NE
                <span className="mil-thin">GARSONKY</span>
              </h2>
            </div>
          </div>
          <div className="row justify-content-center align-items-center mil-mb-30">
            <div className="col-auto">
              <BasicControlBtn />
            </div>
            <div className="col-auto">
              <CustomControlBtn />
            </div>
          </div>

          <Slider {...settings} ref={sliderRef} className="mil-mb-60">
            <BeforeModel />
            <AfterModel />
          </Slider>
        </div>
      </div>
    </section>
  );
};

const BeforeModel = () => {
  return (
    <>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-5 col-xl-5">
          <div className="mil-mb-20">
            <h3 className="mil-up mil-mb-60">BEFORE</h3>
            <p className="mil-up mil-mb-30">
              V této garsonce prostor působil chaoticky a neorganizovaně.
              Kuchyně a postel byly těsně vedle sebe, což omezovalo soukromí a
              pohodlí. Když se člověk podíval kolem, bylo jasné, že prostor
              nebyl využíván na maximum. Bez promyšleného uspořádání bylo těžké
              cítit se v bytě skutečně doma. Nepořádek byl nejen fyzický, ale i
              emocionální – lidé se tu nemohli plně uvolnit.
            </p>
            <p className="mil-up mil-mb-60">
              Tento byt potřeboval něco víc než jen nábytek; potřeboval proměnu,
              která by mu vrátila život a funkčnost.
            </p>
            <h4 className="mil-up">
              <span className="mil-thin">Využijeme </span> každý centimetr{" "}
              <br />
              <span className="mil-thin">vašeho</span> prostoru.
            </h4>
          </div>
        </div>
        <div className="col-lg-7">
          <img
            src={`${process.env.PUBLIC_URL}/img/floorplans/Before_nbg.png`}
            className="carousel-image"
            alt="Image 3"
          />
        </div>
      </div>
    </>
  );
};

const AfterModel = () => {
  return (
    <>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-5 col-xl-5">
          <div className="mil-mb-20">
            <h3 className="mil-up mil-mb-60">AFTER</h3>
            <p className="mil-up mil-mb-30">
              Po rekonstrukci se garsonka proměnila v praktický a útulný
              prostor. Vestavba umístěná uprostřed bytu efektivně odděluje
              jednotlivé zóny, což vytváří příjemné prostředí pro každodenní
              život.
            </p>
            <p className="mil-up mil-mb-60">
              Kuchyňská linka na čele vestavby je nyní srdcem bytu, kde se
              vaření stává radostí a společné chvíle s rodinou či přáteli jsou
              na dosah. Za rohem se nachází ložnice, která nabízí potřebné
              soukromí a klid na odpočinek po náročném dni. Koupelna a WC jsou
              elegantně umístěny uvnitř vestavby, což zajišťuje maximální
              využití prostoru bez pocitu stísněnosti. Každý kousek byl navržen
              tak, aby přinesl pohodlí a funkčnost, a tím přispěl k lepšímu
              životu. Tento prostor nyní poskytuje všechny důležité zóny a
              zároveň dává prostor kreativnímu vyjádření.
            </p>
            <h4 className="mil-up">
              <span className="mil-thin">Využijeme </span> každý centimetr{" "}
              <br />
              <span className="mil-thin">vašeho</span> prostoru.
            </h4>
          </div>
        </div>
        <div className="col-lg-7">
          <img
            src={`${process.env.PUBLIC_URL}/img/floorplans/after_nbg.png`}
            className="carousel-image"
            alt="Image 1"
          />
        </div>
      </div>
    </>
  );
};

export default Products;
