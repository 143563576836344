import React from "react";

import Preloader from "./components/Preloader.js";
import Banner from "./components/contentPages/Banner.js";
import Story from "./components/contentPages/Story.js";
import Products from "./components/contentPages/Products.js";
import ContactForm from "./components/contentPages/ContactForm.js";
import Realization from "./components/contentPages/Realization.js";
import Footer from "./components/Footer.js";

const MainPage = () => {

  return (
    <>
      <Preloader />
      <Banner />
      <Products />
      <Story />
      <Realization />
      <ContactForm />
      <Footer />
    </>
  );
};

export default MainPage;