import React from "react";

const LogoLight = ({ className, ...props}) => {
  return (
    <svg
      id="a"
      data-name="Logo Negarsonka Light"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 719.86 58"
      className={className}
      {...props}
    >
      <defs>
        <style>
          {`
            .b {
                fill: none;
                stroke: #00a9b5;
                stroke-miterlimit: 10;
                stroke-width: 7px;
            }

            .c {
                fill: #00a9b5;
            }

            .c, .d {
                stroke-width: 0px;
            }

            .d {
                fill: #fff;
            }
        `}
        </style>
      </defs>
      <path
        className="c"
        d="M71.54,54.14V4.94h51.12v8.72h-41.52v10.56h28.88v8.72h-28.88v12.48h43.36v8.72h-52.96Z"
      />
      <path
        className="d"
        d="M195.23,28.7c0,15.92-12.56,26.88-32.08,26.88-8.72,0-16.48-2.48-22-6.48-6.88-5.04-10.32-11.6-10.32-19.68s3.44-14.48,10.32-19.52c5.68-4.16,13.6-6.64,22.56-6.64,10.96,0,19.76,3.68,27.04,11.44l-9.04,4.48c-5.12-4.88-11.2-7.2-18.56-7.2-13.12,0-22.4,7.52-22.4,17.44s8.88,17.52,22.4,17.52c9.92,0,16.88-3.92,20.08-11.04h-22.16v-8.72h34.16v1.52Z"
      />
      <path
        className="d"
        d="M253.63,54.14l-5.68-10.56h-33.04l-5.68,10.56h-10.56l26.8-49.2h11.92l26.8,49.2h-10.56ZM231.46,13.1l-11.76,21.76h23.44l-11.68-21.76Z"
      />
      <path
        className="d"
        d="M319.86,54.14l-12.88-18.08h-24.4v18.08h-9.6V4.94h39.76c10.48,0,17.12,6.16,17.12,15.6,0,7.92-4.24,13.2-12.24,14.96l13.28,18.64h-11.04ZM320.02,20.7c0-4.88-2.96-7.04-9.28-7.04h-28.16v13.68h28.72c5.76,0,8.72-2.32,8.72-6.64Z"
      />
      <path
        className="d"
        d="M368.18,12.06c-10.88,0-16.4,2-16.4,5.84,0,5.36,8.72,4.56,22.96,6,15.44,1.52,22.88,5.04,22.88,14.8,0,10.48-9.6,16.56-27.6,16.56-12.64,0-22.64-2.8-30.32-8.64l5.2-6.8c6.64,4.64,15.12,7.12,24.96,7.12,11.36,0,17.44-3.12,17.44-7.76,0-2.56-1.84-4.48-5.6-5.52-5.36-1.52-12.96-1.04-22.24-1.92-12.08-1.12-17.76-4.96-17.76-12.8,0-9.28,9.44-15.28,26.32-15.28,11.04,0,20.08,2.4,26.96,7.2l-5.92,6.24c-6.56-3.44-13.36-5.04-20.88-5.04Z"
      />
      <rect className="b" x="411.02" y="3.5" width="92" height="51" />
      <path
        className="d"
        d="M561.54,54.14l-33.44-41.04v41.04h-9.6V4.94h14.32l33.44,41.04V4.94h9.6v49.2h-14.32Z"
      />
      <path
        className="d"
        d="M639.7,54.14l-23.44-24.96-14,10.08v14.88h-9.6V4.94h9.6v24.08l33.52-24.08h14.16l-26.24,18.88,28.4,30.32h-12.4Z"
      />
      <path
        className="d"
        d="M709.3,54.14l-5.68-10.56h-33.04l-5.68,10.56h-10.56l26.8-49.2h11.92l26.8,49.2h-10.56ZM687.14,13.1l-11.76,21.76h23.44l-11.68-21.76Z"
      />
      <path
        className="c"
        d="M43.04,54.14L9.6,13.1v41.04H0V4.94h14.32l33.44,41.04V4.94h9.6v49.2h-14.32Z"
      />
    </svg>
  );
};

export default LogoLight;
