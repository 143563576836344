import { useEffect } from 'react';

const useSVGManager = () => {
  useEffect(() => {
    const addSVGs = () => {

      // zasraný šipky
      if (document.querySelectorAll('.mil-arrow-place .mil-arrow').length === 0) {
        const arrowSvg = document.querySelector('.mil-arrow');
        const arrowPlaces = document.querySelectorAll('.mil-arrow-place');

        if (arrowPlaces.length > 0 && arrowSvg) {
          arrowPlaces.forEach(arrowPlace => {
            arrowPlace.appendChild(arrowSvg.cloneNode(true));
          });
        }
      }

      // kostka vypíčený
      if (document.querySelectorAll('.mil-animation .mil-dodecahedron').length === 0) {
        const dodecahedron = document.querySelector('.mil-dodecahedron');
        const animationPlaces = document.querySelectorAll('.mil-animation');

        if (animationPlaces.length > 0 && dodecahedron) {
          animationPlaces.forEach((animationPlace) => {
            animationPlace.appendChild(dodecahedron.cloneNode(true));
          });
        }
      }

      // vyjebaný čáry
      if (document.querySelectorAll('.mil-lines-place .mil-lines').length === 0) {
        const line = document.querySelector('.mil-lines');
        const linesPlaces = document.querySelectorAll('.mil-lines-place');
        if (linesPlaces.length > 0 && line) {
          linesPlaces.forEach((linePlace) => {
            linePlace.appendChild(linePlace.cloneNode(true));
          });
        }
      }

      // posraný linky - je potřeba zjistit co to kurva je a případně to pak opravit, až se to bude používat
      // if (document.querySelectorAll('.mil-current-page .mil-main-menu ul li.mil-active > a').length === 0) {
      //   const activeLink = document.querySelector('.mil-main-menu ul li.mil-active > a');
      //   const currentPagePlaces = document.querySelector('.mil-current-page');

      //   // if (currentPagePlaces.length > 0 && activeLink) {
      //   //   activeLinks.forEach((activeLink) => {
      //   //     currentPagePlace.appendChild(activeLink.cloneNode(true));
      //   //   });
      //   // }
      // }
    };

    setTimeout(() => {
      addSVGs();
    }, 10);
  }, []);
};

export default useSVGManager;
