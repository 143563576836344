import React, { useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import $ from "jquery";

const LoadMainJS = () => {
  useEffect(() => {
    window.$ = window.jQuery = $;
    scrollAnimations();
  }, []);

  const scrollAnimations = () => {
    $(document).on("click", 'a[href^="#"]', function (event) {
      event.preventDefault();

      var target = $($.attr(this, "href"));
      var offset = 0;

      if ($(window).width() < 1200) {
        offset = 90;
      }

      $("html, body").animate(
        {
          scrollTop: target.offset().top - offset,          
        },
        400
      );
    });
  };


  return (
    <HelmetProvider>
      <Helmet>
        <script
          src={`${process.env.PUBLIC_URL}/scripts/plugins/fancybox.min.js`}
          type="text/javascript"
        />
        {/* <script
          src={`${process.env.PUBLIC_URL}/scripts/plugins/smooth-scroll.js`}
          type="text/javascript"
        /> */}
        <script
          src={`${process.env.PUBLIC_URL}/scripts/plugins/tilt.js`}
          type="text/javascript"
        />
      </Helmet>
    </HelmetProvider>
  );
};

export default LoadMainJS;
