import { useEffect } from 'react';
import {
  animateAppearance,
  animateScaleImage,
  animateParallaxImage,
  animateRotate,
} from '../assets/animations';

const useAnimations = () => {
  useEffect(() => {
    const appearanceElements = document.querySelectorAll('.mil-up');
    const scaleImageElements = document.querySelectorAll('.mil-scale');
    const parallaxImageElements = document.querySelectorAll('.mil-parallax');
    const rotateElements = document.querySelectorAll('.mil-rotate');

    animateAppearance(appearanceElements);
    animateScaleImage(scaleImageElements);
    animateParallaxImage(parallaxImageElements);
    animateRotate(rotateElements);
  }, []);
};

export default useAnimations;
