import React from "react";
import { Link } from "react-router-dom";
import LogoLight from "./contentPages/LogoLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTiktok,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Footer = () => {
  const handleLinkClick = (event) => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute("href");
    gsap.to(window, { duration: 1, scrollTo: { y: 0, autoKill: true } }).then(() => {
      window.location.href = target;
    });
  };

  return (
    <footer id="footer" className="mil-dark-bg">
      <div className="mi-invert-fix">
        <div
          className="container mil-p-120-60"
          style={{ margin: "auto", justifyContent: "center" }}
        >
          <div className="row justify-content-between mil-mb-60">
            <div className="col-lg-6 col-md-12 mil-mb-60">
              <div className="mil-logo mil-up mil-mb-30">
                <a href="/">
                  <LogoLight style={{ width: "300px", height: "auto" }} />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="row justify-content-start">
                <div className="col-lg-6 col-md-12">
                  <ul className="mil-menu-list mil-up mil-mt-60">
                    <li>
                      <Link to="/" className="mil-light-soft" onClick={handleLinkClick}>
                        Úvodní stránka
                      </Link>
                    </li>
                    <li>
                      <Link to="/gdpr" className="mil-light-soft" onClick={handleLinkClick}>
                        Zásady ochrany osobních údajů
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-around flex-md-row">
            <div className="col-md-12 col-lg-6 mil-mb-60">
              <h6 className="mil-muted mil-up">Česká republika</h6>
              <p className="mil-light-soft mil-up">Praha</p>
            </div>
            <div className="col-md-12 col-lg-6 mil-mb-60">
              <div className="mil-vert-between">
                <div className="mil-mb-30">
                  <ul className="mil-social-icons mil-up">
                    <li>
                      <a
                        href="https://www.instagram.com/negarsonka"
                        target="_blank"
                        className="social-icon"
                        itemProp="sameAs"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          className="white-icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/negarsonka"
                        target="_blank"
                        className="social-icon"
                        itemProp="sameAs"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFacebook}
                          className="white-icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/@negarsonka"
                        target="_blank"
                        className="social-icon"
                        itemProp="sameAs"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faYoutube}
                          className="white-icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/negarsonka"
                        target="_blank"
                        className="social-icon"
                        itemProp="sameAs"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faXTwitter}
                          className="white-icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://TikTok.com/@negarsonka"
                        target="_blank"
                        className="social-icon"
                        itemProp="sameAs"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faTiktok}
                          className="white-icon"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <p className="mil-light-soft mil-up">
                  © Copyright NEGARSONKA 2024 - All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
