import React from "react";
import LogoLight from "./contentPages/LogoLight";

const TopLogoFrame = () => {
    return (
      <div className="mil-frame-top">
        <div className="mil-logo">
          <a href="#banner"><LogoLight className="mil-logo container" /></a>
        </div>
      </div>
    );
};

export default TopLogoFrame;    